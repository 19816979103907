import React, { useEffect, useState } from "react";
import { MsalProvider } from "@azure/msal-react";
import msalInstance from "./msalConfig";

const MSALProvider = ({ children }: { children: React.ReactNode }) => {
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        const initializeMsal = async () => {
            try {
                await msalInstance.initialize();
                setIsInitialized(true);
            } catch (error) {
                console.error("Failed to initialize MSAL:", error);
            }
        };

        initializeMsal();
    }, []);

    if (!isInitialized) {
        return <div>Loading...</div>;
    }

    return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

export default MSALProvider;
