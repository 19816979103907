import React from "react";
import { CollapsibleTable } from "../../Pages/CashupHome/CashupTabs/POS/utils/Panel";
import { Space } from "antd";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { useQuery } from "react-query";
import { getAccountsQuery } from "../../ApiV2/Helpers/getAccountsQuery";
import { CCUPanel } from "./CCUPanel";
import { ExtendedLocationItemWithChildren } from "../../Redux/StateSlices/GroupData/LocationsAPI";
import { LocationType } from "Pages/CashupReport/CashupReportModel";

interface Props {
    loading: boolean;
    locations: ExtendedLocationItemWithChildren[];
}

const CCUTab = ({ loading, locations = [] }: Props) => {
    const { venueId, formattedDate, shiftStatus, setActivePanel, activePanel } =
        useVenueSelection();
    const { data: accounts, isLoading: accountsIsLoading } = useQuery(
        ["accounts", venueId],
        () => getAccountsQuery(venueId)
    );

    if (loading || accountsIsLoading) {
        return <></>;
    }

    return (
        <Space direction="vertical" style={{ width: "100%", marginTop: "20px" }}>
            {locations
                .filter(
                    (ccuLocation) => ccuLocation.location_type === LocationType.ccu
                )
                .map((currentLocation, index) => {
                    const panelId = `ccu-${formattedDate}-${index}`;

                    return (
                        <CollapsibleTable
                            name={currentLocation.name}
                            panelId={panelId}
                            key={panelId}
                        >
                            <CCUPanel
                                key={currentLocation.name}
                                name={currentLocation.name}
                                //@ts-ignore
                                cashupIds={currentLocation.cashup_ids}
                                //@ts-ignore
                                subLocations={currentLocation.sub_locations}
                                locations={locations}
                                hierarchicalLocations={[...locations]}
                                accountsData={accounts}
                                onClickHandler={() => {
                                    setActivePanel(`${panelId}`);
                                }}
                                isInFocus={panelId === activePanel}
                                submitted={shiftStatus !== "UNSUBMITTED"}
                            />
                        </CollapsibleTable>
                    );
                })}
        </Space>
    );
};
export default CCUTab;
