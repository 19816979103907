import { ApiCall } from "../apiCall";

export const getAustracVenueReport = async (
    venueId: string,
    startDate: string,
    endDate: string
) => {
    const requestUrl = `?from_date=${startDate}&venue_id=${venueId}&to_date=${endDate}`;
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "CASHUPREPORT",
        "admin-austrac",
        "GET",
        {},
        requestUrl
    );
};
