import { SettingOutlined, FileTextOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import "./userAvatarDropdown.scss";
import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "../../Router/RoutesEnum";
import { resetCache } from "Redux/logoutHelper";
import { AccessIdToken, AuthTokens } from "utils/auth0/types";
import jwtDecode from "jwt-decode";
import type { MenuProps } from "antd";
import { useAppDispatch } from "Redux/TypedReduxFunctions";
import { resetVenue } from "Redux/StateSlices/ShiftReportState";
import { useQuery } from "react-query";
import { getLoggedInUser } from "../../ApiV2/Helpers/getLoggedInUser";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { useMsal } from "@azure/msal-react";

export enum PermissionType {
    CreateUser = "create:users",
    AuditReportDownload = "cashup:superadmin",
    ShiftDatesApprove = "cashup:shift-dates-approve",
}

/**
 * Returns true if access_token.permissions array contains string 'create:users'
 *
 *
 * @returns
 */
export const userManagerCheck = (userPermission: PermissionType): boolean => {
    const accessToken = window.localStorage.getItem(AuthTokens.accessToken);
    if (!accessToken) return false;
    return (
        (jwtDecode(accessToken) as AccessIdToken).permissions?.find(
            (permission) => permission === userPermission
        ) !== undefined
    );
};

export default function UserAvatarDropdown(): ReactElement {
    const router = useHistory();
    const dispatch = useAppDispatch();
    const { setVenueId, setChannelId, setVenueName } = useVenueSelection();
    const { instance } = useMsal();
    const logout = () => {
        // The auth state listener will clear redux store on exit.
        resetCache();
        instance.logout();

        dispatch(resetVenue());
        router.push(RoutePaths.LOGIN);
        setVenueId("");
        setChannelId("");
        setVenueName("");
    };
    const { data: user, refetch } = useQuery([], () => getLoggedInUser(), {
        refetchOnWindowFocus: true, // Refreshes on window focus
        refetchOnMount: true, // Refreshes on component mount
        refetchOnReconnect: true, // Refreshes when the network reconnects
    });
    const userMenu: MenuProps["items"] = [
        {
            key: 1,
            label: (
                <Menu.Item key="0">
                    <a rel="noreferrer">{user?.email}</a>
                </Menu.Item>
            ),
        },

        {
            key: 3,
            label: user &&
                (user.role === "QUANTACO_ADMIN" || user.role === "SUPER_ADMIN") && (
                    <Menu.Item key="1">
                        <a href={RoutePaths.AUDIT_REPORT} rel="noreferrer">
                            Audit Report
                        </a>
                    </Menu.Item>
                ),
        },
        {
            key: 4,
            label: (
                <Menu.Item key="3">
                    <a rel="noreferrer" onClick={logout}>
                        Log out
                    </a>
                </Menu.Item>
            ),
        },
    ];

    return (
        <>
            {user &&
                (user.role === "QUANTACO_ADMIN" || user.role === "SUPER_ADMIN") && (
                    <a href={RoutePaths.AUDIT_REPORT} rel="noreferrer">
                        <Tooltip
                            title={"Audit Report"}
                            mouseEnterDelay={0.2}
                            placement="bottom"
                        >
                            <FileTextOutlined
                                style={{
                                    fontSize: "28px",
                                    backgroundColor: "#f0f0f0",
                                    borderRadius: "50%",
                                    marginLeft: "20px",
                                }}
                            />
                        </Tooltip>
                    </a>
                )}
        </>
    );
}
