import { ApiCall } from "../apiCall";

export const putAustracQuestionResponse = async (
    venueId: string,
    responseId: string,
    data: FormData
) => {
    const requestUrl = `/${venueId}/austrac/responses/${responseId}`;
    return await ApiCall(
        data,
        "AUTHENTICATED",
        "AUSTRAC",
        "patch",
        "PATCH",
        {},
        requestUrl
    );
};
