import React, { ReactElement } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./Router/Router";
import "./App.less";
import "./numberInput.css";
import { store } from "./Redux/store";
import { Provider } from "react-redux";
import { PusherProvider } from "hooks/usePusher";
import { ConfigProvider } from "antd";
import { QUANTACO_DARK_BLUE } from "@Constants/theme/constants";
import { ModalProvider } from "hooks/useModal";
import { LoaderProvider } from "hooks/loaderProvider";
import MSALProvider from "./Pages/MSALProvider";
// Extend window object for gtag (Google analytics tag)
//trigger a deployment
declare global {
    interface Window {
        gtag: (...args: any[]) => void;
        dataLayer: Record<string, any>;
    }
}

function App(): ReactElement {
    const msalConfig = {
        auth: {
            clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
            authority: process.env.REACT_APP_MSAL_AUTHORITY,
            redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false,
        },
    };
    // @ts-ignore

    return (
        <Provider store={store}>
            <MSALProvider>
                <PusherProvider>
                    <BrowserRouter>
                        <ConfigProvider
                            theme={{
                                token: {
                                    colorPrimary: QUANTACO_DARK_BLUE,
                                },
                            }}
                        >
                            <ModalProvider>
                                <LoaderProvider>
                                    <AppRouter />
                                    {/*<TokenErrorModal />*/}
                                </LoaderProvider>
                            </ModalProvider>
                        </ConfigProvider>
                    </BrowserRouter>
                </PusherProvider>
            </MSALProvider>
        </Provider>
    );
}

export default App;
