import { KeyNumberContainer } from "../../Pages/CashupReportNew/Components/CashupReportStyled";
import { Statistic } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { getKeyGamingFiguresQuery } from "../../ApiV2/Helpers/getKeyGamingFiguresQuery";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";
import { usePusher } from "../../hooks/usePusher";
import { Channel } from "pusher-js";

export const GamingKeyFigures: React.FC = () => {
    const { venueId, formattedDate, channelId } = useVenueSelection();
    const { pusher } = usePusher();
    const topBannerSubscriptionRef = useRef<Channel>();
    const [gamingFiguresLoading, setGamingFiguresLoading] = useState<boolean>(false);
    const { data: gamingKeyFigures, isLoading: gamingKeyFiguresIsLoading } =
        useQuery(
            ["gamingKeyFigures", venueId],
            () => getKeyGamingFiguresQuery(venueId, formattedDate),
            {
                enabled: venueId != null && venueId !== "",
            }
        );
    const [gamingFigures, setGamingFigures] = useState(gamingKeyFigures);
    const AUSTRAC_COMPLIANCE_KEY = "Austrac Compliance";

    useEffect(() => {
        setGamingFigures(gamingKeyFigures);
    }, [gamingKeyFigures]);

    useEffect(() => {
        if (!pusher) return;
        if (channelId !== "") {
            topBannerSubscriptionRef.current = pusher.subscribe(channelId);
            topBannerSubscriptionRef.current.bind("gaming_figures", (data: any) => {
                setGamingFigures(data);
                setGamingFiguresLoading(false);
            });
            topBannerSubscriptionRef.current.bind("gaming_figures_updating", () => {
                setGamingFiguresLoading(true);
            });
        }
    }, [pusher, channelId, venueId]);
    if (
        gamingKeyFiguresIsLoading ||
        !gamingKeyFigures ||
        !gamingFigures ||
        gamingFiguresLoading
    )
        return (
            <div
                style={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "20px", // Optional: Adds some spacing between the containers
                }}
            >
                {[1, 2, 3, 4, 5, 6, 7].map((e) => (
                    <KeyNumberContainer
                        key={e}
                        style={{
                            flex: 1,
                            minWidth: "180px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <CentredSpinner />
                    </KeyNumberContainer>
                ))}
            </div>
        );

    return (
        <div
            style={{
                margin: "auto",
                display: "flex",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                alignItems: "center",
                textAlign: "center",
                gap: "10px", // Optional: Adds some spacing between the containers
            }}
        >
            {Object.entries(gamingFigures).map(([key, value]) => (
                <KeyNumberContainer
                    key={key}
                    style={{
                        flex: 1,
                        minWidth: "180px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Statistic
                        valueStyle={{
                            fontSize: 28,
                            fontWeight: "700 !important",
                            color:
                                key === AUSTRAC_COMPLIANCE_KEY &&
                                (value as number) > 0 &&
                                (value as number) < 2
                                    ? "#EB4242"
                                    : "#113469",
                        }}
                        value={value as number}
                        precision={2}
                        suffix={key !== "Return To House" ? "" : "%"}
                        prefix={
                            key !== "Return To House" &&
                            key !== AUSTRAC_COMPLIANCE_KEY
                                ? "$"
                                : ""
                        }
                    />
                    <div
                        style={{
                            fontSize: 16,
                            fontWeight: "SemiBold",
                            textAlign: "center",
                        }}
                    >
                        {key}
                    </div>
                </KeyNumberContainer>
            ))}
        </div>
    );
};
