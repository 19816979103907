import { ApiCall } from "../apiCall";

export async function getAustracQuestions(venueId: string, shiftDate: string) {
    const requestUrl = `/${venueId}/austrac/responses?shift_date=${shiftDate}`;
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "AUSTRAC",
        "list",
        "GET",
        {},
        requestUrl
    )
        .then((res) => res.json())
        .then((res) => res.data.austrac_responses);
}
