import React, { useState, useMemo } from "react";
import { AustracReportItem } from "@interfaces/index";
import { CustomDataTable, TotalTableContainer } from "../CashupReportStyled";
import { austracPromptsColumnGenerator } from "../../utils";

interface AustracItemProps {
    austracItemData: AustracReportItem;
}

const AustracPrompts: React.FC<AustracItemProps> = ({ austracItemData }) => {
    const [sortedColumn, setSortedColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<"ascend" | "descend" | null>(
        null
    );

    //column generator
    const columns = useMemo(
        () => austracPromptsColumnGenerator(sortedColumn, sortDirection),
        [sortedColumn, sortDirection]
    );

    //handle sorting
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setSortedColumn(sorter.columnKey);
        setSortDirection(sorter.order as "ascend" | "descend" | null);
    };

    return (
        <>
            {austracItemData.responses.length > 0 ? (
                <TotalTableContainer>
                    <CustomDataTable
                        rowKey="id"
                        dataSource={austracItemData.responses}
                        columns={columns}
                        size="small"
                        scroll={{ x: true }}
                        tableLayout="fixed"
                        pagination={false}
                        onChange={handleTableChange}
                    />
                </TotalTableContainer>
            ) : null}
        </>
    );
};

export default AustracPrompts;
