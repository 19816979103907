import React, { useEffect } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { Auth } from "../utils/auth0/auth";
import { useMsal } from "@azure/msal-react";
import { useGetUserEmail } from "../@hooks/auth/getUserEmail";
import { useWebviewLogin } from "../@hooks/useWebviewLogin";
import { QuantacoLoader } from "../Components/QuantacoLoader/QuantacoLoader";

export function AuthProtectedRoute({ component: Component, ...rest }: RouteProps) {
    const isLoading = useWebviewLogin();
    const { auth0User, loaded } = useGetUserEmail(isLoading);
    const msalSignIn = `https://launcher.myapps.microsoft.com/api/signin/${process.env.REACT_APP_MSAL_UUID}?tenantId=b8f7b107-c1cc-4e76-a3e3-2d7fa7f1e226`;
    const router = useHistory();
    const authZero = new Auth();
    useEffect(() => {
        document.title = "Cashup - Login";
    }, []);

    const { instance, accounts } = useMsal();

    useEffect(() => {
        const getMsToken = async () => {
            if (accounts.length > 0) {
                try {
                    const response = await instance.acquireTokenSilent({
                        scopes: ["User.Read", "Application.Read.All"],
                        account: accounts[0],
                    });
                    // Store the ms token in localStorage
                    localStorage.setItem("ms_token", response.accessToken);
                } catch (error) {
                    console.error("Token acquisition failed:", error);
                }
            }
        };
        getMsToken();
    }, [instance, accounts]);

    useEffect(() => {
        if (!auth0User && loaded) {
            if (authZero.isIdPInitiatedSSO()) {
                authZero
                    .silentAuth()
                    .then(() => {
                        if (window.location.hash) {
                            window.history.replaceState(
                                null,
                                "",
                                window.location.pathname
                            );
                            window.location.reload();
                        }
                    })
                    .catch((err) => {
                        if (err.error === "consent_required") {
                            console.warn(
                                "Consent required. Redirecting to login..."
                            );
                            authZero.interactiveAuth();
                        } else {
                            console.error("Silent authentication failed:", err);
                            // Handle other errors appropriately
                        }
                    });
            } else {
                window.location.href = msalSignIn;
            }
        }
        // else if (!accounts[0]) {
        //     window.location.href = "/login";
        // }
    }, [router, loaded, auth0User]);

    if (!loaded || isLoading || !auth0User) {
        return <QuantacoLoader size={200} />;
    }

    return <Route {...rest} component={Component} />;
}
