import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { QuantacoLoader } from "../../Components/QuantacoLoader/QuantacoLoader";

const LoginPage = () => {
    const { instance, inProgress } = useMsal();
    const [isInitializing, setIsInitializing] = useState(true);
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        const handleAuth = async () => {
            try {
                if (inProgress == "none") {
                    const tokenResponse = await instance.handleRedirectPromise();
                    if (!tokenResponse) {
                        const accounts = instance.getAllAccounts();
                        if (accounts.length === 0 && inProgress === "none") {
                            // No user signed in and no interaction in progress
                            await instance.loginRedirect();
                        }
                    }
                    // Authentication process complete
                    setIsInitializing(false);
                }
            } catch (err) {
                // Handle error
                console.error(err);
                setIsInitializing(false);
            }
        };
        handleAuth();
    }, [instance, inProgress]);
    if (isAuthenticated) {
        window.location.href = "/";
    }

    if (isInitializing) {
        return <QuantacoLoader size={200} />;
    }

    return <QuantacoLoader size={200} />;
};

export default LoginPage;
