export enum CashupStatus {
    draft = 1,
    unapproved = 2,
    approved = 3,
}

export enum CashupReportType {
    PosClasses = "venue-pos-classes",
    PosTenders = "venue-pos-tenders",
    PosVariances = "venue-pos-variances",
    PosClassesSummary = "venue-pos-classes-summary",
    PosTendersSummary = "venue-pos-tenders-summary",
    PosVariancesSummary = "venue-pos-variances-summary",
    GamingMeteredNetProfit = "venue-gaming-meteredNetProfit",
    GamingActualNetProfit = "venue-gaming-actualNetProfit",
    GamingPayouts = "venue-gaming-payouts",
    WageringPayouts = "venue-wagering-payouts",
    TransactionsPettyCash = "venue-transactions-pettyCash",
    GamingVariance = "venue-gaming-gamingVariance",
    AdminShiftReport = "venue-admin-shiftReport",
    AdminShiftMetrics = "venue-admin-shiftReport-prompts",
    ApprovalsReport = "venue-admin-approvalsReport",
    BankableReport = "venue-admin-bankable",
    BankableOverview = "venue-admin-bankableOverview",
    AustracCompliance = "venue-admin-austracCompliance",
    GroupPosClasses = "group-pos-classes",
    GroupPosTenders = "group-pos-tenders",
    GroupPosVariances = "group-pos-variances",
    GroupGamingMeteredNetProfit = "group-gaming-meteredNetProfit",
    GroupGamingActualNetProfit = "group-gaming-actualNetProfit",
    GroupGamingVariance = "group-gaming-gamingVariance",
    GroupAdminShiftReport = "group-admin-shiftReport",
}

export enum BankableOverviewType {
    "open_balance" = "Open Balance",
    "cash_increase" = "Cash Increase",
    bankable = "Bankable",
}
