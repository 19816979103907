import { ApiCall } from "../apiCall";

export async function getNotifications() {
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "NOTIFICATIONS",
        "list",
        "GET",
        {},
        `/?show_all=true`
    )
        .then((res) => res.json())
        .then((res) => res.data.results);
}
