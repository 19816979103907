import { AntDFormStateForGaming, AntDFormStateWithoutSplit } from "@types";
import { Button, Collapse, Result, Row } from "antd";
import React from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import styled from "styled-components";
import {
    PaymentTransactionInit,
    TransferTransactionInit,
} from "../CashupTabs/POS/@types";
import { TransferModalFormV2 } from "./TransactionForms/TransferModalFormV2";
import { PaymentModalFormV3 } from "./TransactionForms/PaymentModalFormV3";
import { StyledFormContainer } from "./TransactionForms/Components/FormContainer";
import { ModalFooter } from "Components/Form/ModalFooter";
import { DraggableModal } from "Components/DraggableModal";
import useTransactionModal from "hooks/useTransactionModal";
import useTransactionTransfer from "hooks/useTransactionTransfer";
import useTransactionGaming from "hooks/useTransactionGaming";
import { LocationType } from "Pages/CashupReport/CashupReportModel";

const { Panel } = Collapse;

interface Props {
    onModalClose?: () => void;
    locations: ExtendedLocationItem[];
    onTransferTransactionSubmission: (data: AntDFormStateWithoutSplit[]) => void;
    TransferTransactions: TransferTransactionInit[];
    TransferTransactionsReadOnly: TransferTransactionInit[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    currentLocationID: string;
    PaymentTransactionData: PaymentTransactionInit[];
    accountsData: ExtendedAccountItem[];
    onPaymentTransactionSubmission: (data: AntDFormStateForGaming[]) => void;
    onPaymentTransactionRemove: (data: AntDFormStateForGaming[]) => void;
    location_type?: LocationType;
    disabled: boolean;
    loading?: boolean;
}

const StyledPanelHeader = styled.div`
    font-weight: bold;
`;

const TransactionsFormGamingComponent: React.FC<Props> = ({
    onModalClose,
    onPaymentTransactionSubmission,
    onPaymentTransactionRemove,
    TransferTransactionsReadOnly,
    currentLocationID,
    PaymentTransactionData,
    accountsData,
    location_type,
    disabled,
    loading,
}) => {
    const effectiveLocationType = location_type ?? 0;

    const {
        closeModal,
        formNamesNeedToSave,
        isVisible,
        setFormNeedToSave,
        setIsVisible,
    } = useTransactionModal({ onModalClose });

    const {
        initialTransfersReadOnlyTransactionsDataWithAppendedFormID,
        transferFieldsReadOnly,
    } = useTransactionTransfer({
        currentLocationID,
        TransferTransactionsReadOnly,
    });

    const {
        paymentFields,
        tenderAccounts,
        initialPaymentTransactionsDataWithAppendedFormID,
    } = useTransactionGaming({ accountsData, PaymentTransactionData });

    return (
        <StyledFormContainer>
            <Collapse accordion expandIconPosition="end">
                {initialTransfersReadOnlyTransactionsDataWithAppendedFormID.length >
                    0 && (
                    <Panel
                        header={<StyledPanelHeader>Transfers</StyledPanelHeader>}
                        key="2"
                    >
                        <TransferModalFormV2
                            fields={transferFieldsReadOnly}
                            initialDataForForms={
                                initialTransfersReadOnlyTransactionsDataWithAppendedFormID
                            }
                            onChange={() => null}
                            currentLocationID={currentLocationID}
                            disabled={disabled}
                        />
                    </Panel>
                )}

                {effectiveLocationType === 0 && (
                    <Panel
                        header={<StyledPanelHeader>Payout</StyledPanelHeader>}
                        key="4"
                    >
                        <PaymentModalFormV3
                            fields={paymentFields}
                            initialDataForForms={
                                initialPaymentTransactionsDataWithAppendedFormID
                            }
                            onSubmit={onPaymentTransactionSubmission}
                            onRemove={onPaymentTransactionRemove}
                            tenderAccounts={tenderAccounts}
                            disabled={disabled}
                            setFormNeedToSave={setFormNeedToSave}
                            loading={loading}
                        />
                    </Panel>
                )}
            </Collapse>
            <ModalFooter
                onClose={closeModal}
                primaryButtonText="Close"
                hideCancelButton={true}
            />
            <DraggableModal
                open={isVisible}
                onCancel={() => setIsVisible(false)}
                onOk={() => setIsVisible(false)}
                destroyOnClose
                style={{ top: 30, borderRadius: "12px" }}
                bodyStyle={{ padding: "0px 24px 20px 24px" }}
                zIndex={100000}
                cancelButtonProps={{ style: { display: "none" } }}
                footer={null}
                noBottomBorder
                width={"80%"}
            >
                <Result
                    status="warning"
                    title={`${formNamesNeedToSave} not saved, please save your changes before leave`}
                    extra={
                        <Row style={{ justifyContent: "center" }}>
                            <Button onClick={() => setIsVisible(false)}>OK</Button>
                        </Row>
                    }
                />
            </DraggableModal>
        </StyledFormContainer>
    );
};

export const TransactionsFormGaming = React.memo(TransactionsFormGamingComponent);
