import { ApiCall } from "../apiCall";

export async function postNotification(notificationId: string) {
    const requestUrl = `/${notificationId}/read/`;

    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "NOTIFICATIONS",
        "list",
        "POST",
        {},
        requestUrl
    )
        .then((res) => res.json())
        .then((res) => res.data);
}
